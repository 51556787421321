* {box-sizing: border-box;}

form#contactus{
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding: 40px;
    background: rgba(0, 0, 0, 0.455);
    border-radius: 20px;
}

form#contactus::before{
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);
    background: rgba(255, 0, 0, 0.664);
    z-index: -1;
    position: absolute;
}

form#contactus input[type=text],form#contactus select,form#contactus textarea {
    width: 100%;
    border: none;
    padding: 12px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    border-radius: 10px !important;
    resize: vertical;
}

form#contactus input {
    border-radius: 10px !important;
}

form#contactus input[type=submit] {
    background: rgba(255, 0, 0, 0.664);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

form#contactus input[type=submit]:hover {
  background-color: rgb(14, 14, 167);
}
