.footer-box{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #0470e4;
    color: white;
    font-weight: bolder;
    letter-spacing: 2px;
}
.footer-box a{
    color: white;
}
.footer-box a:hover{
    color: red;
    text-shadow: 1px 1px 10px 2px white;
}

