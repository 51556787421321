.card {
    background-color: white;
    padding: 10px 20px 11px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }
  .pay-button{
    padding: 0.7rem 2rem;
    width: 100%;
    margin: 1rem 0;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;
  }
  .pay-button:disabled{
    background-color: #afafaf;
    box-shadow: none;
    cursor: default;
  }
  .pay-button:disabled:hover{
    box-shadow: none;
  }
  .pay-button:hover{
    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  }

  .header-second{
    width: 100%;
    height: 100px;
    background: #3160E0;
  }

  .billingDtl{
    min-height: 100vh;
    padding: 40px 0;
  }
  
  .step-progress{
    background: rgba(170, 167, 167, 0.515);
    border-radius: 20px;
    margin: 20px 0 30px;
    border: 1px solid rgba(70, 68, 68, 0.611);
  }
  .step-progress .row{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  .step-progress .row div{
    margin: 0 50px;
    height: 40px;
    width: 150px;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .step-progress .row div{
    cursor: pointer !important;
  }
  .step-progress .row div:hover{
    background: rgba(0, 0, 0, 0.384);
  }

  .step-progress .row div.active{
    background: #3160E0;
    color: white;
  }

  .issame{
    cursor: pointer;
  }

  .addTransition{
    padding: 10px;
    z-index: 90;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .addTransition .addNew{
    display: none;
  }
  .addTransition .addNew.active{
    display: block;
  }
  .img_div{
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .invoice-data .row{
    color: black;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }
  .invoice-data .imp{
    color: orange;
    font-weight: bolder;
  }

  .outer-div.row{
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .img-cover-box{
    max-width: 200px;
    height: max-content;
    margin: 10px;
    position: relative;
    cursor: pointer;
  }

  .video-cover-box{
    max-width: max-content !important;
    height: max-content;
    margin: 10px;
    position: relative;
    cursor: pointer;
  }

  .img-cover-box:hover .crose{
    display: flex;
  }
  
  .img-cover-box .crose{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgba(0, 0, 0, 0.568);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: none;
  }
  .img-cover-box .crose span{
    width: 30px;
    height: 30px;
    background: red;
    color: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hide{
    display: none;
  }