#gBtn {
    width: 100%;
}

#gBtn button{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid black !important; */
    box-shadow: 0 0 10px -1px grey !important;
    padding: 7px !important;
}
#gBtn button > div{
    margin: 0 !important;
    padding: 0 !important;
}

#gBtn button span{
    font-weight: bolder !important;
    text-transform: capitalize !important;
    margin: 0; 
    padding: 0 !important;
    padding-top: 3px !important;
    margin-left: 10px;
}