.gallary-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.gallary-main img{
    max-width: 200px;
    height: auto;
    margin: 2px;
    border-radius: 2px;
}
