navbar{
    z-index: 999999;
}

.navbar-brand img{
    max-width: 60px;
}
.nav-item{
    padding: 0 7px;
    border-radius: 15px;
    border: 2px solid transparent;
}

.nav-item.active{
    border: 2px dotted white;
}

.name-area{
    border-bottom: 2px dotted white;
    padding: 2px 10px;
}

.navbar-area.non-top .logo-text {
    color: #2E2E2E;
    font-weight: bold;
    padding-left: 0;
    margin-left: 10px;
}