.about-text{
    font-family: cursive;
    letter-spacing: 2px;
}

.email-div a{
    padding: 10px;
    background: rgba(204, 187, 38, 0.514);
    color: black;
    border: 1px solid black;
    cursor: pointer;
    width: 50%;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 150%;
    font-weight: bolder;
    letter-spacing: 1px;
}

.team-image{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
}
.team-image img{
    width: 100%;
    height: 100%;
}
