.product-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    /* background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
    /* background-image: repeating-linear-gradient(135deg, rgba(0,0,0,0.04) 0px, rgba(0,0,0,0.04) 2px,transparent 2px, transparent 4px),linear-gradient(135deg, rgb(165, 230, 235),rgb(29, 32, 232)); */
    /* background-image: repeating-linear-gradient(135deg, rgba(0,0,0,0.03) 0px, rgba(0,0,0,0.03) 2px,transparent 2px, transparent 4px),linear-gradient(45deg, rgb(18, 105, 233),rgb(166, 255, 155)); */
    /* background-image: repeating-linear-gradient(135deg, rgba(255,255,255,0.04) 0px, rgba(255,255,255,0.04) 2px,transparent 2px, transparent 4px),linear-gradient(45deg, rgb(228, 105, 225),rgb(16, 69, 109)); */
    padding: 50px 20px;
    border-radius: 15px;
}

.product-details .product-image{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.product-details .product-image img{
    border-radius: 10px;
}

::-webkit-scrollbar{
    width: 2px;
}

.product-details .product-images-list{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;

    margin: 10px 0;
}
.product-details .product-images-list .sub-image{
    min-width: 100px;
    max-width: 100px;
    margin: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.product-details .dtl{
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, .15); 
    backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 20px;
    /* margin: 0 10px; */
}
.product-details .dtl h1{
    font-weight: bolder;
}
.product-details .dtl h3{
    font-size: 100%;
}
.product-details .dtl h3 span{
    font-weight: bolder;
}
.product-details .dtl .pricing{
    font-size: 120%;
    margin-bottom: 10px;
}
.product-details .dtl .pricing span{
    margin: 5px;
}
.product-details .dtl .pricing span:nth-of-type(1){
    font-weight: bolder;
    font-size: 120%;
}
.product-details .dtl .pricing span:nth-of-type(2){
    /* font-size: 90%; */
}
.product-details .dtl .pricing span:nth-of-type(3){
    font-weight: bolder;
    color: green;
}
.product-details .dtl .btns button{
    text-transform: uppercase;
}
.product-details .dtl .btns button span{
    font-weight: bolder;
    color: black;
}
.sizing-con > label{
    color: black;
    font-weight: bolder;
}
.sizing-con .col-12 input,
    .sizing-con .col-12 label{
    margin-bottom: 0rem !important;
    cursor: pointer;
}
.sizing-con .col-12 input{
    margin-right: 5px;
}
.sizing-con .col-12 label{
    color: black;
}
.coupon-input{
    margin: 10px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.coupon-input .c-field button{
    margin-top: 0 !important;
    margin-left: 10px;
}
.coupon-input .c-field input{
    color: black !important;
}
.coupon-input .q{
    width: 100%;
    color: green;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.coupon-input .q input,
    .coupon-input .q label{
    margin-bottom: 0 !important;
    cursor: pointer;
}
.coupon-input .q input{
    margin-right: 5px !important;
}
.coupon-input .row input{
    margin-right: 5px !important;
    border: 1px solid black;
}
.coupon-input .row input::placeholder{
    color: grey;
}


.coupon-input span.danger{
    color: red;
}
.coupon-input span.success{
    color: white;
}

.coupon-input input{
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
    background: transparent;
    color: white;
    cursor: pointer;
}
.coupon-input input::placeholder{
    color: white;
}
.coupon-input input:focus{
    border: 2px solid yellow;
}

.tools{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 90;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.tools button{
    margin: 10px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.833);
    color: white;
    border-radius: 50%;
    border: none;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.awssld__content div{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}
.awssld{
    /* --organic-arrow-color: white !important; */
    --content-background-color: transparent !important;
}

.features_list{
}
.features_list ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.features_list ul li{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    font-weight: bolder;
    margin: 4px 0;
}
.features_list ul li span{
    font-weight: normal;
}

.product-details .card-header{
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    border: none;
    border: 2px solid #5b3c60;
    padding: 0 20px;
    border-radius: 10px;
}

.product-details .card-header img{
    border-radius: 10px;
    width: 100%;
}