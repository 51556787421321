.logo-text{
    color: white;
    margin-left: 5px;
    font-weight: bolder;
}
.header-hero-content .text{
    margin-top: 0;
}
.header-hero-image{
    padding-top: 0;
    transform: translateY(29px);
}

.services-title{
    color: #333ED0;
}

.services-content .text{
    color: #a90986 !important;
}

.section-title .title{
    color: #341b2f !important;
}

section{
    min-height: 80vh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.video-content{
    padding: 30px;
    border: 3px solid transparent;
    background: white;
    box-shadow: 0 5px 20px -11px black;
    border-radius: 20px;
}

.video-content:hover{
    border-radius: 20px;
    border: 3px solid steelblue;
}

.video-wrapper{
    margin-right: 0;
}