.orders{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.orderItem{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    background: rgba(176, 179, 184, 0.494);
    border-radius: 10px;
    padding: 20px;
    border: 3px solid steelblue;
}
.orderItem .img{
    width: 100px;
    max-width: 100px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 10px;
}
.name{
    color: black;
    font-weight: bolder;
}
.orderItem .img img{
    width: 100%;
}
.orderItem .dtl{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.orderItem .dtl div{
    display: flex;
    flex-direction: column;
}

